<template>
  <v-list-item @click="isSuperuserOverride = !isSuperuserOverride">
    <div class="flex items-center justify-between">
      <v-list-item-title class="text-gray-500">
        View as {{ isSuperuserOverride ? "user" : "admin" }}
      </v-list-item-title>
      <v-icon
        class="mr-1"
        size="small"
        :icon="isSuperuserOverride ? mdiAccount : mdiAccountStar"
      />
    </div>
  </v-list-item>
  <v-list-group
    v-if="isSuperuserOverride"
    :expand-icon="mdiChevronRight"
    :collapse-icon="mdiChevronRight"
    class="mb-4"
    color="primary"
    aria-label="Debug"
    role="listitem"
  >
    <template #activator="{ props: activatorProps }">
      <v-list-item
        v-bind="activatorProps"
        title="Debug"
      >
        <template #append>
          <v-icon
            class="toggle-icon mr-1"
            size="small"
          >
            {{ mdiChevronRight }}
          </v-icon>
        </template>
      </v-list-item>
    </template>

    <AppSettingsMultiSelect
      v-model="debugSubscriptions"
      label="Subscriptions"
      :items="debugSubscriptionOptions"
      :track-event="undefined"
    />

    <AppSettingsMultiSelect
      v-model="selectedFeatureFlags"
      label="Feature Flags"
      :items="featureFlagOptions"
      :track-event="undefined"
    />

    <AppSettingsSwitch
      v-model="logTrackEventsToConsole"
      label="Log Track Events To Console"
      :track-event="undefined"
    />
    <AppSettingsSwitch
      v-model="logTrackEventsToAws"
      label="Log Track Events to AWS"
      :track-event="undefined"
    />
    <AppSettingsSwitch
      v-model="isStaffOverride"
      label="Staff User"
      :track-event="undefined"
    />
    <AppSettingsSwitch
      v-model="isDummyData"
      label="Broker Review Dummy Data"
      :track-event="undefined"
    />
  </v-list-group>
  <hr class="m-2" />
</template>

<script setup="setup" lang="ts">
import { mdiAccount, mdiAccountStar, mdiChevronRight } from "@mdi/js";
import { VIcon, VListGroup, VListItem } from "vuetify/lib/components/index.mjs";

import { useAppStore } from "@/store/app";
import { availableSubscriptions } from "~/store/app.types";
import { useBrokerReview } from "~/store/brokerReview";

import AppSettingsMultiSelect from "./AppSettingsMultiSelect.vue";
import AppSettingsSwitch from "./AppSettingsSwitch.vue";

const {
  subscriptions,
  isSuperuserOverride,
  isStaffOverride,
  featureFlags,
  logTrackEventsToConsole,
  logTrackEventsToAws,
} = storeToRefs(useAppStore());

const { isDummyData } = storeToRefs(useBrokerReview());

const debugSubscriptionOptions: { title: string; value: Subscription }[] =
  availableSubscriptions.map((value) => ({ value, title: toTitle(value) }));

const debugSubscriptions = ref(
  debugSubscriptionOptions
    .map((e) => e.value)
    .filter((e) => subscriptions.value[e])
);

watch(debugSubscriptions, (values) => {
  objectKeys(subscriptions.value).forEach((key) => {
    subscriptions.value[key] = values.includes(key);
  });
});

const featureFlagOptions: { title: string; value: FeatureFlag }[] = [
  { title: "Trade Date Data", value: "showTradeDateData" },
];

const selectedFeatureFlags = ref<FeatureFlag[]>(
  featureFlagOptions.map((e) => e.value).filter((e) => featureFlags.value[e])
);

watch(selectedFeatureFlags, (values) => {
  objectKeys(featureFlags.value).forEach((key) => {
    featureFlags.value[key] = values.includes(key);
  });
});
</script>
