import { captureException } from "@sentry/vue";

import { useAppStore } from "./app";

// This store is kept separate from app store to prevent circular dependencies
export const useUserSettingsStore = defineStore("userSettings", () => {
  const data = ref<Dictionary>({});

  const initialised = ref(false);

  function writeToDynamoDb_noDebounce() {
    const { userProfile } = useAppStore();
    const userSettings = useUserSettingsStore();

    if (!userProfile) {
      captureException(
        "No user profile found in store. Cannot save user settings."
      );
      return Promise.resolve();
    }

    // Remove empty settings objects
    for (const key in userSettings.data) {
      if (Object.keys(userSettings.data[key]).length === 0) {
        delete userSettings.data[key];
      }
    }

    return $fetch("/api-v2/user-settings", {
      method: "PUT",
      body: {
        email: userProfile.email,
        settings: userSettings.data,
      },
    });
  }

  const writeToDynamoDb = debounce(() => {
    const { userProfile } = useAppStore();
    const userSettings = useUserSettingsStore();

    if (!userProfile) {
      captureException(
        "No user profile found in store. Cannot save user settings."
      );
      return;
    }

    // Remove empty settings objects
    for (const key in userSettings.data) {
      if (Object.keys(userSettings.data[key]).length === 0) {
        delete userSettings.data[key];
      }
    }

    $fetch("/api-v2/user-settings", {
      method: "PUT",
      body: {
        email: userProfile.email,
        settings: userSettings.data,
      },
      ignoreResponseError: true,
      onResponse(e) {
        if (!e.response.ok) {
          GlobalBus.$emit(
            "snackbar:error",
            "An error occured while trying to save user settings"
          );
        }
      },
    });
  }, 1000);

  return {
    data,
    initialised,
    writeToDynamoDb,
    writeToDynamoDb_noDebounce,
  };
});
