import type { Account } from "@/models";
import { createDateRangeArray } from "@/utils/createDateRangeArray";
import { maxSafeDateMs, UTC } from "@/utils/UTC";
import { allBrokers } from "~/components/BrokerReview/Comparison/allBrokers";
import type { DataItemValue } from "~/components/BrokerReview/types";

import { useAppStore } from "./app";
import { type DateQuickSelectOption, useDate } from "./useDate";

export type UseDateBrokerReview = typeof useBrokerReview;

export const useBrokerReview = defineStore("brokerReview", () => {
  const { accounts, defaultDateRange } = storeToRefs(useAppStore());

  const yesterday = UTC().subtract(1, "day");

  const {
    startDate,
    endDate,
    selectedDate,
    cachedDateRange,
    setPeriod,
    getQuickSelectOptions,
  } = useDate();

  startDate.value = yesterday.format("YYYY-MM-DD");
  endDate.value = yesterday.format("YYYY-MM-DD");
  selectedDate.value = yesterday.format("YYYY-MM-DD");

  const inceptionDate = computed<string>(
    () =>
      accounts.value
        .map((e) => e.start_date)
        .filter(Boolean)
        .sort((a, b) => +UTC(a) - +UTC(b))
        .at(0) ?? yesterday.format("YYYY-MM-DD")
  );

  /*
   * Latest date any pipeline has data.
   *  - Defaults to yesterday if no data received
   *  - Cannot display todays date
   */

  function accountPipelineHasRunOnDate(date: string) {
    return (account: Account) =>
      // Account is active on date
      +UTC(account.end_date ?? maxSafeDateMs) >= +UTC(date) &&
      // Account has run pipeline
      !account.missing_dates.includes(date);
  }

  const latestDate = computed<string>(() => {
    const dateRange = createDateRangeArray(inceptionDate.value, yesterday);

    const latestAccountDate = dateRange.findLast((date) =>
      accounts.value.find(accountPipelineHasRunOnDate(date))
    );

    return latestAccountDate ?? endDate.value;
  });

  function setDatesToLatest(quickSelectOptions: DateQuickSelectOption[]) {
    const selectedOption =
      quickSelectOptions.find((e) => e.title === defaultDateRange.value) ??
      quickSelectOptions[0];

    const [defaultStart, defaultEnd] = selectedOption.value;

    const startDateMS = Math.max(+defaultStart, +UTC(inceptionDate.value));

    startDate.value = toISOstring(UTC(startDateMS));
    endDate.value = toISOstring(defaultEnd);
    selectedDate.value = toISOstring(defaultEnd);

    if (import.meta.env.VITE_SELECTED_DATE) {
      selectedDate.value = import.meta.env.VITE_SELECTED_DATE;
    }
    if (import.meta.env.VITE_START_DATE) {
      startDate.value = import.meta.env.VITE_START_DATE;
    }
    if (import.meta.env.VITE_END_DATE) {
      endDate.value = import.meta.env.VITE_END_DATE;
    }
  }

  const dateQuickSelectOptions = getQuickSelectOptions(
    inceptionDate,
    latestDate
  );

  watch(dateQuickSelectOptions, setDatesToLatest, { immediate: true });

  const allowedDateMinMs = computed(() => +UTC(inceptionDate.value));
  const allowedDateMaxMs = computed(() => +UTC(latestDate.value));

  function isDisabledDate(date: Date) {
    const dateMs = +UTC(date).startOf("day");
    return dateMs < allowedDateMinMs.value || dateMs > allowedDateMaxMs.value;
  }

  /******** STATE ********/
  const period = useSetting<"quarter" | "year">("period", {
    default: "quarter",
    section: "broker_review",
  });
  const isAnnual = computed(() => period.value === "year");

  const selectedColumnTypes = ref<DataItemValue[]>([
    "aggregated_value",
    "rank",
    "main_value",
    "percent_of_total",
  ]);

  const anonymisedBrokerColumns = ref([
    "value",
    "percent_of_total",
    "rank",
    "ytd_curr",
  ]);

  const appStore = useAppStore();
  const { availablePrimeBrokers } = storeToRefs(appStore);

  const focusedBroker = useSetting("focusedBroker", {
    default: availablePrimeBrokers.value[0],
    section: "broker_review",
    validator: isOneOf(...availablePrimeBrokers.value),
  });

  const isDummyData = ref(!useRuntimeConfig().public.isLocal);

  const brokers = computed(() =>
    isDummyData.value ? allBrokers : availablePrimeBrokers.value
  );

  const selectedBrokers = useSetting<ApiFilterItem[]>("selectedBrokers", {
    default: toValue(brokers).slice(1),
    section: "broker_review",
    validator: (value, _type) =>
      Array.isArray(value) &&
      value.every((e) =>
        isOneOf(...availablePrimeBrokers.value)(e, _type?.[0])
      ),
  });

  watch(isDummyData, () => {
    focusedBroker.value = brokers.value[0];
    selectedBrokers.value = toValue(brokers).slice(1);
  });

  const isAnonymisedBrokerData = useSetting("isAnonymisedBrokerData", {
    default: false,
    section: "broker_review",
  });
  const isAnonymisedBrokerName = useSetting("isAnonymisedBrokerName", {
    default: false,
    section: "broker_review",
  });

  const dates = computed(() =>
    createDateMsRangeArray(startDate.value, endDate.value)
  );

  const randomArr = computed(() =>
    Array.from({ length: Math.floor(dates.value.length / 30) }, () => {
      return Math.floor(Math.random() * dates.value.length);
    })
  );

  const availablePeriods = computed(() => {
    return period.value === "quarter"
      ? [
          "2024 Q2",
          "2024 Q1",
          "2023 Q4",
          "2023 Q3",
          "2023 Q2",
          "2023 Q1",
          "2022 Q4",
          "2022 Q3",
        ]
      : ["2024", "2023", "2022"];
  });

  return {
    allowedDateMax: latestDate,
    allowedDateMin: inceptionDate,
    cachedDateRange,
    endDate,
    selectedDate,
    startDate,
    isDisabledDate,
    setPeriod,
    //
    period,
    isAnnual,
    focusedBroker,
    selectedBrokers,
    isAnonymisedBrokerName,
    isAnonymisedBrokerData,
    selectedColumnTypes,
    anonymisedBrokerColumns,
    randomArr,
    availablePeriods,
    isDummyData,
    brokers,
  };
});
