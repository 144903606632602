import type { Component, CSSProperties } from "vue";
import type { ComponentProps } from "vue-component-type-helpers";

import type { ToolTipText } from "~/assets/tooltipLookup";
import { precisionTypes } from "~/store/app.types";

import DataTableCellAction, {
  useProvideAction,
} from "./Cell/DataTableCellAction.vue";
import type { CurrencyCell } from "./Cell/DataTableCellCurrency.vue";
import DataTableCellCurrency, {
  getGroupValueCurrency,
} from "./Cell/DataTableCellCurrency.vue";
import DataTableCellNumber, {
  escapeMultiCcy,
  escapeNonAggregatingQuantities,
  getGroupValueAggregateNumber,
} from "./Cell/DataTableCellNumber.vue";
import DataTableCellState from "./Cell/DataTableCellState.vue";
import DataTableCellString, {
  getGroupValueUnique,
} from "./Cell/DataTableCellString.vue";
import DataTableFilterNumber from "./DataTableFilterNumber.vue";
import DataTableFilterState from "./DataTableFilterState.vue";
import DataTableFilterString from "./DataTableFilterString.vue";
import type { DataTableColumn, DataTableItem, Options } from "./dataTableTypes";

type GetCellProps<T extends Component> = Omit<
  ComponentProps<T>,
  /** DataTable internal props */
  | "column"
  | "item"
  | "items"
  | "path"
  | "id"
  /** Vue common props */
  | "class"
  | "style"
  | "key"
  | "ref"
  | "ref_for"
  | "ref_key"
  | "onVnodeBeforeMount"
  | "onVnodeBeforeUnmount"
  | "onVnodeBeforeUpdate"
  | "onVnodeMounted"
  | "onVnodeUnmounted"
  | "onVnodeUpdated"
>;

type IncludeCellProps<CellType extends Component> =
  RequiredKeysOf<GetCellProps<CellType>> extends never
    ? { cellProps?: GetCellProps<CellType> }
    : { cellProps: GetCellProps<CellType> };

const createColumnGroup = <T extends DataTableItem>(
  group: string,
  columns: DataTableColumn<T>[]
): DataTableColumn<T>[] => {
  return columns.map((e) => ({ ...e, group }));
};

type ColumnOptions<T extends DataTableItem> = Partial<{
  type: DataTableColumn["type"];
  groupable: boolean;
  sortable: boolean;
  filterable: boolean;
  filterCell: Component;
  getColor: (item: T & { isTotal?: boolean }, children?: T[]) => string;
  sort: (a: any, b: any) => number;
  getSortValue: (item: T) => any;
  isFilteredFromDownload: boolean;
  class: any;
  style: string | CSSProperties;
  valueGroup: string;
  group: string;
  isHiddenWhenCollapsed: boolean;
  tooltip: string;
  tooltipId: ToolTipText;
  getGroupValue: (items: T[], column: DataTableColumn) => any;
  minWidth: number;
  defaultWidth: number;
  autosize: boolean;
  colspan: number;
}>;

type ColumnParams<T extends DataTableItem> = {
  title: string;
  value: keyof T & string;
} & ColumnOptions<T>;

const createColumn = <T extends DataTableItem, CellType extends Component>({
  autosize = false,
  cell,
  cellProps,
  class: className,
  colspan = 1,
  defaultWidth,
  filterCell,
  filterable = true,
  getColor,
  getGroupValue = () => undefined,
  getSortValue = (item: T) => item[value],
  group,
  groupable = false,
  isFilteredFromDownload = false,
  isHiddenWhenCollapsed,
  minWidth = 100,
  sort = () => 0,
  sortable = true,
  style = "",
  title,
  tooltip,
  tooltipId,
  type,
  value,
  valueGroup = "",
}: ColumnParams<T> & {
  cell: CellType;
} & IncludeCellProps<CellType>): DataTableColumn<T> => {
  return {
    cell: markRaw(cell),
    cellProps: cellProps ?? {},
    class: className,
    defaultWidth,
    filterCell,
    filterable: Boolean(filterable && filterCell),
    getColor,
    getGroupValue,
    groupable,
    isFilteredFromDownload,
    minWidth,
    autosize,
    sort,
    getSortValue,
    sortable,
    style,
    title,
    tooltip,
    tooltipId,
    type,
    value,
    valueGroup,
    group,
    isHiddenWhenCollapsed,
    colspan,
  };
};

/** STRINGS */

type ColumnStringParams<T extends DataTableItem> = {
  title: string;
  value: StringOrNullOnly<T>;
} & GetCellProps<typeof DataTableCellString> &
  ColumnOptions<T>;

const createColumnString = <T extends DataTableItem>({
  value,
  formatter,
  ...options
}: ColumnStringParams<T>) =>
  createColumn({
    type: "string",
    value: value as keyof T & string,
    groupable: true,
    sort: (a, b) => a.localeCompare(b),
    cell: DataTableCellString,
    filterCell: markRaw(DataTableFilterString),
    getGroupValue: getGroupValueUnique,
    cellProps: {
      formatter,
    },
    ...options,
  });

/** NUMBERS */

type NumberParams<T extends DataTableItem> = {
  title: string;
  value: NumberOrNullOnly<T>;
  precision?: [number, number];
} & Omit<GetCellProps<typeof DataTableCellNumber>, "precision"> &
  ColumnOptions<T>;

const createColumnNumber = <T extends DataTableItem>({
  value,
  numberFormatOptions,
  currencyKey,
  precision = [2, 2],
  scale,
  isDifference,
  ...options
}: NumberParams<T>) =>
  createColumn<T, typeof DataTableCellNumber>({
    type: "number",
    value,
    groupable: false,
    sort: (a, b) => a - b,
    cellProps: {
      numberFormatOptions,
      currencyKey,
      precision,
      scale,
      isDifference,
    },
    cell: DataTableCellNumber,
    filterCell: markRaw(DataTableFilterNumber),
    getGroupValue: getGroupValueAggregateNumber,
    autosize: true,
    minWidth: 0,
    ...options,
  });

export type ColumnCurrencyParams<T extends DataTableItem> = {
  currencyKey?: StringOnly<T> & string;
} & NumberParams<T>;

type ColumnDynamicCurrencyParams<T extends DataTableItem> = {
  value: KeysOfType<T, CurrencyCell>;
} & Omit<NumberParams<T>, "value"> &
  GetCellProps<typeof DataTableCellCurrency>;

const createColumnDynamicCurrency = <T extends DataTableItem>({
  value,
  precision,
  numberFormatOptions,
  isDifference,
  ...options
}: ColumnDynamicCurrencyParams<T>) =>
  createColumn<T, typeof DataTableCellCurrency>({
    type: "number",
    value,
    groupable: false,
    getSortValue: (item) => item[value]?.base,
    sort: (a, b) => a - b,
    cellProps: {
      numberFormatOptions: {
        style: "currency",
        currencyDisplay: "code",
        ...numberFormatOptions,
      },
      precision,
      isDifference,
    },
    cell: DataTableCellCurrency,
    // filterCell TODO:
    getGroupValue: getGroupValueCurrency,
    autosize: true,
    ...options,
  });

/** ACTIONS */

type ColumnActionParams<T extends DataTableItem> = {
  title: string;
  value: string;
} & GetCellProps<typeof DataTableCellAction<T>> &
  ColumnOptions<T>;

const createColumnAction = <T extends DataTableItem>({
  value,
  show,
  tooltipText,
  tooltipProps,
  icon,
  ariaLabel,
  ...options
}: ColumnActionParams<T>) =>
  createColumn<T, typeof DataTableCellAction<T>>({
    value: value as keyof T & string,
    sortable: false,
    groupable: false,
    isFilteredFromDownload: true,
    cell: DataTableCellAction,
    cellProps: {
      show,
      tooltipText,
      tooltipProps,
      ariaLabel,
      icon,
    },
    minWidth: 48,
    autosize: true,
    ...options,
  });

/** DATE */

type ColumnDateParams<T extends DataTableItem> = {
  title: string;
  value: StringOrNullOnly<T> & string;
  fallback?: string;
  groupValue?: "earliest" | "latest" | "unique";
} & GetCellProps<typeof DataTableCellString> &
  ColumnOptions<T>;

const createColumnDate = <T extends DataTableItem>({
  value,
  fallback = "",
  groupValue = "unique",
  ...options
}: ColumnDateParams<T>) =>
  createColumn({
    value,
    filterCell: markRaw(DataTableFilterString),
    cell: DataTableCellString,
    getGroupValue(items, column) {
      if (groupValue === "unique") {
        return getGroupValueUnique(items, column);
      }

      const dates = items
        .sort((a, b) => {
          if (!a[value]) return 1;
          if (!b[value]) return -1;

          return this.getSortValue?.(a as any) - this.getSortValue?.(b as any);
        })
        .map((e) => e[value]);

      return groupValue === "earliest" ? dates.at(0) : dates.at(-1);
    },
    cellProps: {
      formatter(value) {
        if (value === "Multiple" || value === "") {
          return "";
        }
        // Null: use fallback if available
        return value ? UTC(value).format("DD MMM YYYY") : fallback;
      },
    },
    getSortValue: (item) => +UTC(item[value]),
    sort: (a, b) => a - b,
    autosize: true,
    ...options,
  });

/** STATE */

type ColumnStateParams<T extends DataTableItem> = {
  title: string;
  value: keyof T;
} & GetCellProps<typeof DataTableCellState> &
  ColumnOptions<T>;

const createColumnState = <T extends DataTableItem>({
  value,
  states,
  ...options
}: ColumnStateParams<T>) =>
  createColumn<T, typeof DataTableCellState>({
    value: value as keyof T & string,
    groupable: true,
    sort: (a, b) => a.localeCompare(b),
    cell: DataTableCellState,
    filterCell: markRaw(DataTableFilterState),
    cellProps: {
      states,
    },
    getGroupValue: getGroupValueUnique,
    ...options,
  });

type ColumnBooleanParams<T extends DataTableItem> = {
  title: string;
  value: keyof T;
  titleTrue?: string;
  titleFalse?: string;
} & ColumnOptions<T>;

function createOptions<T extends DataTableItem>(
  init: Partial<Options<T>>,
  useSettingConfig?: { section?: SectionKey; nameSuffix?: string }
): Options<T> {
  const overlapCols = init.selectedColumns?.filter(
    (v) => init.groupBy?.includes(v) || init.fixedColumns?.includes(v)
  );

  if (overlapCols?.length) {
    console.error(
      `Group by and Selected arrays have overlapping values: ${JSON.stringify(
        overlapCols
      )}`
    );

    init.selectedColumns = init.selectedColumns?.filter(
      (v) => !overlapCols.includes(v)
    );
  }

  const isPaginated = init.itemsPerPage || init.itemsPerPageOptions?.length;
  const isPaginationValid =
    typeof init.itemsPerPage === "number" &&
    init.itemsPerPageOptions?.includes(init.itemsPerPage);

  if (isPaginated && !isPaginationValid) {
    console.error(
      "'itemsPerPage' can only be used if 'itemsPerPageOptions' contains that option"
    );
  }

  const defaults: Options<T> = {
    columnFilters: {},
    expandedRows: [],
    groupBy: [],
    itemsPerPage: -1,
    itemsPerPageOptions: [],
    openGroups: [],
    defaultOpenAllGroups: false,
    page: 1,
    search: "",
    selectKey: "_id",
    selectedColumns: [],
    fixedColumns: [],
    selectedItems: [],
    showGroupExpandIcon: true,
    showSelect: false,
    singleItemSelect: false,
    showTotalRow: false,
    sortBy: [],
    sortDesc: [],
    useColumnGroups: true,
    useLinkedHover: false,
    expandedColumnGroups: [],
    singleItemGroups: true,
    serverControls: false,
    showColumnControls: true,
    showItemColor: false,
  };

  const options = reactive({ ...defaults, ...init });

  if (useSettingConfig) useOptionsSetting(options, useSettingConfig);

  return options;
}

function useOptionsSetting<T extends DataTableItem>(
  options: Options<T>,
  useSettingConfig: {
    section?: SectionKey;
    nameSuffix?: string;
  }
) {
  const keys = objectKeys(options);

  const omitKeys = <const>[
    "defaultOpenAllGroups",
    "expandedRows",
    "groupByColumnGroupTitle",
    "itemsPerPageOptions",
    "openGroups",
    "page",
    "search",
    "selectKey",
    "serverControls",
    "serverIsLastPage",
    "showColumnControls",
    "showColumnControls",
    "showGroupExpandIcon",
    "showItemColor",
    "showSelect",
    "showTotalRow",
    "singleItemGroups",
    "singleItemSelect",
    "useColumnGroups",
    "useLinkedHover",
    "useSetting",
  ];

  const pickKeys = keys.filter((k: any) => !omitKeys.includes(k)) as Exclude<
    keyof Options,
    (typeof omitKeys)[number]
  >[];

  const name = ["tableOptions", useSettingConfig.nameSuffix]
    .filter(Boolean)
    .join(":");

  const savedOptions = useSetting(name, {
    default: pick(options, pickKeys),
    section: useSettingConfig.section,
  });

  function sync(update: (key: (typeof pickKeys)[number]) => void) {
    pickKeys.forEach((key) => {
      if (!deepEqual(savedOptions.value[key], options[key])) update(key);
    });
  }

  function updateSetting() {
    // @ts-ignore
    sync((k) => (savedOptions.value[k] = options[k]));
  }

  // Use flush:sync to prevent need for awaiting next tick in tests
  watch(options, updateSetting, { flush: "sync" });

  function readSetting(setting: typeof savedOptions.value) {
    sync((k) => {
      if (!(k in savedOptions.value)) return;

      if (k === "columnFilters") {
        options[k] = hydrateColumnFilters(setting[k]);
        return;
      }

      // @ts-ignore
      options[k] = setting[k];
    });
  }

  // Use flush:sync to prevent need for awaiting next tick in tests
  watch(savedOptions, readSetting, { immediate: true, flush: "sync" });
}

export const createTableHelpers = <T extends DataTableItem>() => {
  return {
    createOptions: (
      options: Partial<Options<T>> = {},
      useSetting?: { section?: SectionKey }
    ) => createOptions(options, useSetting),

    group: createColumnGroup<T>,

    custom: <CellType extends Component>(
      options: ColumnParams<T> & {
        cell: CellType;
      } & IncludeCellProps<CellType>
    ) => createColumn<T, CellType>(options),

    string: (options: ColumnStringParams<T>) => createColumnString(options),

    number: (options: NumberParams<T>) => createColumnNumber(options),

    percent: (options: NumberParams<T>) =>
      createColumnNumber({
        getGroupValue: getGroupValueUnique,
        precision: precisionTypes.rate,
        ...options,
        numberFormatOptions: {
          ...options.numberFormatOptions,
          style: "percent",
        },
      }),

    accrual: (options: ColumnCurrencyParams<T>) =>
      createColumnNumber({
        getGroupValue: escapeMultiCcy(getGroupValueAggregateNumber),
        ...options,
        precision: precisionTypes.accrual,
        numberFormatOptions: {
          ...options.numberFormatOptions,
          style: "currency",
          currencyDisplay: "code",
        },
      }),

    balance: (options: ColumnCurrencyParams<T>) =>
      createColumnNumber({
        getGroupValue: escapeMultiCcy(getGroupValueAggregateNumber),
        precision: precisionTypes.balance,
        ...options,
        numberFormatOptions: {
          ...options.numberFormatOptions,
          style: "currency",
          currencyDisplay: "code",
        },
      }),

    price: (options: ColumnCurrencyParams<T>) =>
      createColumnNumber({
        getGroupValue: escapeNonAggregatingQuantities(getGroupValueUnique),
        precision: precisionTypes.price,
        ...options,
        numberFormatOptions: {
          ...options.numberFormatOptions,
          style: "currency",
          currencyDisplay: "code",
        },
      }),

    quantity: (options: ColumnCurrencyParams<T>) =>
      createColumnNumber({
        getGroupValue: escapeNonAggregatingQuantities(
          getGroupValueAggregateNumber
        ),
        precision: precisionTypes.quantity,
        ...options,
      }),

    currency: (options: ColumnDynamicCurrencyParams<T>) =>
      createColumnDynamicCurrency(options),

    action: (options: ColumnActionParams<T>) => createColumnAction(options),

    provideAction: useProvideAction<T>,

    boolean: ({ titleTrue, titleFalse, ...options }: ColumnBooleanParams<T>) =>
      createColumnState({
        ...options,
        type: "boolean",
        states: new Map([
          [true, { text: titleTrue ?? "True", color: theme.green["500"] }],
          [false, { text: titleFalse ?? "False", color: theme.red["500"] }],
        ]),
        sort: (a, b) => Number(a) - Number(b),
      }),

    date: (options: ColumnDateParams<T>) => createColumnDate(options),

    state: (options: ColumnStateParams<T>) => createColumnState(options),
  };
};
