declare global {
  interface EnvMeta {
    client: string;
    environment: string;
    version: string;
    release_timestamp: string;
    feature_flags: Dictionary<boolean>;
    optimizations_latest_available_fis_date: string;
  }

  interface Profile {
    id: number;
    is_mfa_enabled: boolean;
    is_superuser: boolean;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_staff: boolean;
    is_active: boolean;
    subscriptions: Record<Subscription, boolean>;
  }

  type Subscription = (typeof availableSubscriptions)[number];

  type FeatureFlag = "showTradeDateData";

  type View = (typeof views)[number];

  type FilterGroup = "prime_broker" | "fund" | "account" | "currency";

  interface SelectItem {
    title: string;
    value: string;
    color: string;
    group?: FilterGroup;
    disabled?: boolean;
    alias?: string;
  }

  type ApiFilterItem = SelectItem & { group: FilterGroup };

  type Relationships = [string, string, string];

  type StrategyAccount = {
    id: number;
    title: string;
    type: "cash_pb" | "swap";
    strategy: {
      id: number;
      title: string;
    };
    fund: {
      id: number;
      title: string;
    };
    prime_broker: {
      id: number;
      title: string;
    };
  };

  type StrategyRelationships = [string, string, string, string];

  type StrategyFilterGroup = FilterGroup | "strategy" | "strategy_account";

  type StrategyApiFilterItem = Omit<SelectItem, "group"> & {
    group: StrategyFilterGroup;
  };

  type StrategyViewGroupBy =
    | null
    | "prime_broker"
    | "fund"
    | "strategy"
    | "strategy_account"
    | "currency";

  type StrategyView = { title: string; groupBy: StrategyViewGroupBy };

  type Instrument = {
    issuer: string;
    description: string | null;
    product_type: string | null;
    incorporation_country: string | null;
    par_value_currency: string | null;
    isin: string | null;
    cusip: string | null;
    sedol: string | null;
    bbg_ticker: string | null;
  };
}

export const availableSubscriptions = [
  "wallet",
  "stock_loan_data",
  "billing_compare",
  "broker_analysis",
  "portfolio_add_on",
  "strategies",
] as const;

export const views = [
  {
    title: "Aggregate",
    groupBy: null,
  },
  {
    title: "Prime Broker",
    groupBy: "prime_broker",
  },
  {
    title: "Fund",
    groupBy: "fund",
  },
  {
    title: "Account",
    groupBy: "account",
  },
  {
    title: "Currency",
    groupBy: "currency",
  },
] as const;

export const strategyViews: StrategyView[] = [
  {
    title: "Aggregate",
    groupBy: null,
  },
  {
    title: "Prime Broker",
    groupBy: "prime_broker",
  },
  {
    title: "Fund",
    groupBy: "fund",
  },
  {
    title: "Strategy",
    groupBy: "strategy",
  },
  {
    title: "Strategy Account",
    groupBy: "strategy_account",
  },
  {
    title: "Currency",
    groupBy: "currency",
  },
];

type PrecisionType = "accrual" | "balance" | "price" | "rate" | "quantity";

export const precisionTypes: Record<PrecisionType, [number, number]> = {
  rate: [4, 6],
  quantity: [0, 2],
  accrual: [0, 2],
  balance: [0, 2],
  price: [2, 4],
};
