import { captureMessage } from "@sentry/vue";

import type { Options } from "@/components/DataTable/dataTableTypes";
import { type AppSettings, useAppStore } from "@/store/app";
import { useUserSettingsStore } from "~/store/userSettings";
import { hydrateColumnFilters } from "~/utils/hydrateColumnFilters";
import { migrateSettings } from "~/utils/migrateSettings/migrateSettings";

// State types determine: shape of input data and which migrations to apply
type StateTypes = AppSettings | Options | Dictionary;

type RemoteSettingsState<T extends StateTypes> =
  | T
  | {
      [Key in keyof T]: Ref<T[Key]>;
    };

type RemoteSettingsOptions<T extends StateTypes> = {
  section: SectionKey;
  schema: T extends RemoteSettingsState<AppSettings>
    ? "global"
    : T extends Options<any>
      ? "table"
      : "toolbar";
  autosave?: boolean;
};

const tableIgnoreDefaults: (keyof Options)[] = [
  "itemsPerPageOptions",
  "page",
  "search",
  "useColumnGroups",
  "showTotalRow",
  "showSelect",
  "selectKey",
  "openGroups",
  "useLinkedHover",
  "showGroupExpandIcon",
  "expandedRows",
  "serverControls",
  "serverIsLastPage",
  "showColumnControls",
  "singleItemGroups",
  "singleItemSelect",
  "defaultOpenAllGroups",
  "showColumnControls",
  "showItemColor",
  "groupByColumnGroupTitle",
];

export function useRemoteSettings<T extends StateTypes>(
  state: RemoteSettingsState<T>,
  options: RemoteSettingsOptions<T>,
  validation?: Record<keyof T, (data: unknown) => boolean>
) {
  const useState = reactive(state) as T;
  const defaultState = JSON.stringify(useState);
  const ignore =
    options.schema === "table" ? <(keyof T)[]>tableIgnoreDefaults : [];

  const storageKey = `${options.section}:${options.schema}`;
  const sessionKey = "sessionSettings";

  function applyValidationAndHydration(settings: Partial<T>) {
    const entries = Object.entries(settings) as [keyof T, any][];
    const validatedEntries = entries.filter(
      ([k, v]) => !validation || validation[k]?.(v)
    );

    const withHydratedColumnFilters = validatedEntries.map(([k, v]) => {
      if (options.schema === "table" && k === "columnFilters") {
        return [k, hydrateColumnFilters(v)];
      }
      return [k, v];
    });

    return withHydratedColumnFilters as [keyof T, any][];
  }

  function getSession(): Dictionary {
    return JSON.parse(sessionStorage.getItem(sessionKey) ?? "{}");
  }

  /** Read settings from session storage if available, otherwise read from local storage */
  function readSession() {
    const sessionSettings = getSession();
    const sectionSettings = sessionSettings[storageKey];

    if (sectionSettings) {
      const validatedEntries = applyValidationAndHydration(sectionSettings);

      validatedEntries.forEach(([k, v]) => (useState[k] = v));
      return;
    }

    read();
  }

  /** Save settings to session storage */
  function saveSession() {
    const sessionSettings = getSession();
    sessionSettings[storageKey] = omit(useState, ignore);

    sessionStorage.setItem(sessionKey, JSON.stringify(sessionSettings));
  }

  const userSettings = useUserSettingsStore();

  /**
   * try: Read settings from local storage, apply migrations and validation
   * or : Read settings from dynamo
   * or : Use the default state
   */
  function read() {
    let storedEntries: Partial<T> | undefined;

    // Get settings from dynamo
    const savedSettings = userSettings.data[storageKey];

    if (savedSettings) {
      storedEntries = savedSettings;
    }

    // Read default state when using 'reset' button in toolbar but no saved settings
    if (!storedEntries) {
      // This could always be partial due to functions and other objects not being stringified
      const parsedSettings = <Partial<T>>JSON.parse(defaultState);

      storedEntries = parsedSettings;
    }

    const validatedEntries = applyValidationAndHydration(storedEntries);

    validatedEntries.forEach(([k, v]) => (useState[k] = v));

    // Resave settings so that validations are persisted
    if (savedSettings) save();
  }

  /** Save settings to dynamo and local storage */
  function save() {
    const saveState = omit(useState, ignore);

    if (!deepEqual(userSettings.data[storageKey], saveState)) {
      userSettings.data[storageKey] = saveState;
      userSettings.writeToDynamoDb();
    }
  }

  function clear() {
    if (userSettings.data[storageKey]) {
      delete userSettings.data[storageKey];
      userSettings.writeToDynamoDb();
    }

    const sessionSettings = getSession();

    delete sessionSettings[storageKey];
    sessionStorage.setItem(sessionKey, JSON.stringify(sessionSettings));

    // Update state
    read();
  }

  // On page load, read settings from session storage if available, otherwise read from local storage
  readSession();

  // If autosave is enabled we can get away with only saving to localStorage (and not worry about session storage)
  if (options.autosave) {
    watch(useState, save, { deep: true, flush: "sync" });
  } else {
    GlobalBus.$on("save-view", save);
    GlobalBus.$on("reset-view", read);
    GlobalBus.$on("clear-view", clear);
    watch(useState, saveSession, { deep: true, flush: "sync" });
  }
}

export function migrateLocalSettingsToDynamo() {
  const settingsEntries = Object.entries(localStorage).filter(([k]) =>
    k.startsWith("settings:")
  );

  let isUpdate = false;
  const userSettings = useUserSettingsStore();

  for (const [k, v] of settingsEntries) {
    if (!v || JSON.parse(v)._migratedToDynamo) {
      continue;
    }

    const [_, section, schema] = k.split(":");
    const settingsKey = `${section}:${schema}`;

    const migratedSettings = migrateSettings(v, schema, section);

    const { userProfile } = useAppStore();
    // Mark locally stored settings as migrated to dynamo
    captureMessage(
      `Migrating locally stored settings to dynamo - ${userProfile?.email}`
    );

    if (!deepEqual(userSettings.data[settingsKey], migratedSettings)) {
      userSettings.data[settingsKey] = migratedSettings;
      isUpdate = true;
    }
  }

  if (isUpdate) {
    userSettings
      .writeToDynamoDb_noDebounce()
      .then(() => {
        for (const [k] of settingsEntries) {
          localStorage.removeItem(k);
        }
      })
      .catch((e) => {
        captureMessage(
          `Error writing local settings to dynamo: ${JSON.stringify(e)}`
        );
      });
  } else {
    for (const [k] of settingsEntries) {
      localStorage.removeItem(k);
    }
  }
}
