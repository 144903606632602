import { captureException, captureMessage } from "@sentry/vue";

import type { StockLoanBenchmarkingPercentiles } from "~/models/stock-loan-benchmarking-percentiles";
import type { SummaryInfo } from "~/models/summary-info";
import type { Tiers } from "~/models/tiers";
import { useAppStore } from "~/store/app";
import { useUserSettingsStore } from "~/store/userSettings";

// async function requestMeta() {
//   const appStore = useAppStore();

//   if (appStore.envMeta.client) return;

//   await $fetch("/api/meta/", {
//     onResponse: (e) => {
//       appStore.envMeta = e.response._data;

//       const { environment, version, release_timestamp } = appStore.envMeta;
//       const timestampIso = toISOstring(UTC(release_timestamp));

//       printFormattedMessage([
//         {
//           text: "Zeus",
//           style: `background-color: ${theme.primary}; color: white; padding-right: 0px !important;`,
//         },
//         {
//           text: `- Environment: ${environment} - Version: ${version} - Timestamp: ${timestampIso}`,
//           style: `background-color: ${theme.primary}; color: white;`,
//         },
//       ]);
//     },
//   });
// }

async function requestProfile() {
  const appStore = useAppStore();

  if (appStore.userProfile) return;

  await $fetch("/api/profile/", {
    onResponse: (e) => {
      appStore.userProfile = e.response._data;
      appStore.isSuperuserOverride = e.response._data.is_superuser;
      appStore.isStaffOverride = e.response._data.is_staff;
      appStore.subscriptions = e.response._data.subscriptions;
    },
  });
}

async function requestUserSettings() {
  const userSettingsStore = useUserSettingsStore();
  const { userProfile } = useAppStore();

  if (userSettingsStore.initialised || !userProfile?.email) return;

  await $fetch<Dictionary>("/api-v2/user-settings", {
    query: { email: userProfile.email },
    ignoreResponseError: true,
    onResponse: (e) => {
      checkResponseForErrors(e);

      // TODO: move exception capturing to server
      if (e.response.status !== 200) {
        captureException(
          `Failed to fetch user settings for user ${userProfile.email} with status ${e.response.status}`
        );
        return;
      }

      userSettingsStore.data = e.response._data;
      userSettingsStore.initialised = true;

      migrateLocalSettingsToDynamo();
    },
  });
}

async function requestAccounts() {
  const appStore = useAppStore();

  if (appStore.accounts.length) return;

  await $fetch("/api/accounts/", {
    onResponse: (e) => (appStore.accounts = e.response._data),
  });
}

async function requestStrategyAccounts() {
  const appStore = useAppStore();

  if (appStore.strategyAccounts.length) return;

  await $fetch("/api/strategies/accounts/", {
    onResponse: (e) => {
      checkResponseForErrors(e);

      if (!e.response.ok) return;

      const data: StrategyAccount[] = e.response._data ?? [];
      appStore.strategyAccounts = data.filter(
        (e) => e.title && e.strategy.title
      );

      if (appStore.strategyAccounts.length !== data.length) {
        captureMessage(
          "Some strategy accounts are missing title or strategy title"
        );
      }
    },
    ignoreResponseError: true,
  });

  // For strategies-demo
  if (!appStore.strategyAccounts.length) {
    await $fetch("/api-v2/strategies/accounts/", {
      onResponse: (e) => {
        checkResponseForErrors(e);

        if (e.response.ok) {
          appStore.strategyAccounts = e.response._data;
        }
      },
      ignoreResponseError: true,
    });
  }
}

async function requestTiers() {
  const appStore = useAppStore();

  if (appStore.tiers) return;

  $fetch<Tiers>("/api/tiers/", {
    onResponse: (e) => {
      checkResponseForErrors(e);

      if (e.response.ok) {
        appStore.tiers = e.response._data;
      }
    },
    ignoreResponseError: true,
  });
}

async function requestDispersionPercentiles() {
  const appStore = useAppStore();

  if (appStore.dispersionPercentiles) return;

  $fetch<StockLoanBenchmarkingPercentiles>(
    "/api/stock-loan-benchmarking-percentiles/",
    {
      onResponse: (e) => {
        checkResponseForErrors(e);

        if (e.response.ok) {
          appStore.dispersionPercentiles = e.response._data;
        }
      },
      ignoreResponseError: true,
    }
  );
}

async function requestSummaryInfo() {
  const appStore = useAppStore();

  if (appStore.summaryInfo.length) return;

  $fetch<SummaryInfo[]>("/api/summary-info/", {
    onResponse: (e) => {
      checkResponseForErrors(e);

      if (e.response.ok) {
        appStore.summaryInfo = e.response._data;
      }
    },
    ignoreResponseError: true,
  });
}

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) return;

  const success = await requestProfile()
    .then(async () => {
      const requiredRequests = Promise.all([
        (async function () {
          // Ensure Profile is called before userSettings
          // await requestProfile();
          await requestUserSettings();
        })(),
        // requestMeta(),
        requestAccounts(),
      ]);

      const strategyAccounts = requestStrategyAccounts();
      requestSummaryInfo();
      requestDispersionPercentiles();
      requestTiers();

      await requiredRequests.catch((e) => {
        throw createError({
          data: e,
          message: [
            "There was an error during the setup of the application",
            "Please try again or contact the system admin if the problem persists",
          ].join("\n"),
        });
      });

      if (to.path === "/strategies") {
        await strategyAccounts;
      }

      return true;
    })
    .catch(() => {
      return false;
    });

  if (!success) {
    // temp redirect to login while auth flag is being switched
    return navigateTo(`https://${window.location.host}/login`, {
      external: true,
    });
  }
});
