import dayjs from "dayjs";
import type { RouteLocationNormalizedGeneric } from "vue-router";

import { useAppStore } from "~/store/app";

export default defineNuxtPlugin((_nuxtApp) => {
  const router = useRouter();

  /*** Track Navigation ***/
  // Used for page navigation tracking in RUM
  router.beforeEach(trackNavigation);
  // Used for showing page duration in 'Modules and Pages' dashboard
  router.beforeEach((to) => {
    to.meta.startTime = Date.now();
  });
  router.afterEach(trackPageEnd);

  /***  Track Session End and User Journey ***/
  const sessionStartTime = Date.now();
  window.addEventListener("beforeunload", () => {
    const sessionEndTime = Date.now();
    const sessionDuration = sessionEndTime - sessionStartTime;

    useTrackEvent().trigger({
      name: "End Session",
      sessionDuration,
      sessionStartTime,
      sessionEndTime,
    });
  });
});

function trackNavigation(
  to: RouteLocationNormalizedGeneric,
  from: RouteLocationNormalizedGeneric
) {
  const appStore = useAppStore();
  const { path, hash, query, params, name } = to;
  const pageId = name ? String(name) : path;

  const properties = {
    pageId,
    pageAttributes: {
      path,
      hash,
      query: JSON.stringify(query),
      params: JSON.stringify(params),
      refresh: from.path === to.path,
      from: from.path,
      local_time: dayjs().format("HH:mm:ss"),
      utc_time: dayjs().utc().format("HH:mm:ss"),
      screen_size: `${window.innerWidth}x${window.innerHeight}`,
      breakpoint: getBreakpoint(window.innerWidth),
    },
  };

  if (appStore.logTrackEventsToAws) {
    const { awsRum } = useAwsRum();
    awsRum?.recordPageView(properties);
  }

  if (appStore.logTrackEventsToConsole) {
    console.info(`Track Event: navigation`, properties);
  }
}

function trackPageEnd(
  to: RouteLocationNormalizedGeneric,
  from: RouteLocationNormalizedGeneric
) {
  const now = Date.now();
  const pageStartTime = from.meta.startTime;
  if (typeof pageStartTime !== "number") {
    return;
  }

  const pageDuration = now - pageStartTime;

  useTrackEvent().trigger({
    name: "End Page",
    pageDuration,
    pageStartTime,
    pageEndTime: now,
    pagePath: from.path,
    pageName: (from.name as string) ?? "",
  });
}
